<template>
  <div>
    <div class='row'>
      <div class='col-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header d-flex justify-content-between align-items-center'>
            <vb-headers-card-header :data="{ title: 'Sistem Yöneticileri' }" />
            <a-button class='btn btn-primary btn-with-addon text-nowrap' @click='visibleAddAdminModal = true'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fe fe-plus-circle'></i>
              </span>
              Yeni Yönetici Ekle
            </a-button>
          </div>
          <div class='card-body'>
            <div class='table-responsive text-nowrap'>
              <a-table
                :locale='{emptyText: "Henüz Aktivite Yok"}'
                :pagination='false'
                :loading='loading'
                :columns='columns'
                :data-source='administrators'
                :row-key='(record) => record.id'>
                <template #id='{ record }'>
                  <div>
                    <div><strong>{{ record.id }}</strong></div>
                  </div>
                </template>
                <template #name='{ record }'>
                  <div>
                    <div>{{ record.name }}</div>
                  </div>
                </template>
                <template #email='{ record }'>
                  <div>
                    <div>{{ record.email }}</div>
                  </div>
                </template>
                <template #operation='{record}'>
                  <div>
                    <a-button @click='() => showEditModal(record.id)' class='btn btn-primary mr-1'>
                      <i class='fe fe-settings' />
                    </a-button>
                    <a-popconfirm
                      title='Yöneticiyi Silmek İstediğinize Emin Misiniz?'
                      ok-text='Evet'
                      cancel-text='Hayır'
                      @confirm='confirmDelete(`${record.id}`)'
                    >
                      <a-button class='btn btn-danger'>
                        <i class='fe fe-trash text white' />
                      </a-button>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
              <div class='card-body'>
                <div class='clearfix'>
                  <a-pagination
                    class='float-right'
                    @change='listAdministrators'
                    v-model:page-size='pagination.recordsPerPage'
                    :default-page-size='pagination.recordsPerPage'
                    v-model:current='pagination.currentPage'
                    :default-current='pagination.currentPage'
                    :total='pagination.totalRecords'
                    :show-total='total => `Toplam ${total} kayıt`'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model:visible='visibleAddAdminModal'
      centered
      title='Yeni Yönetici Ekle'
      ok-text='Kaydet'
      cancel-text='Kapat'
      :ok-button-props='{ disabled: !validateForm, loading: loading }'
      @ok='addNewAdmin'
    >

      <a-form
        label-align='left'
        layout='vertical'
      >
        <a-form-item label='Adı (*)' name='name'>
          <a-input v-model:value='name' />
        </a-form-item>
        <a-form-item label='Soyadı (*)' name='lastName'>
          <a-input v-model:value='lastName' />
        </a-form-item>
        <a-form-item label='Mail Adresi (*)' name='email' @keypress='validateEmail(email)'>
          <a-input v-model:value='email' />
          <a-tag color='error' v-if='email !== "" & !validateEmail(email)' class='mt-2'>
            <template #icon>
              <i class='fe fe-alert-circle' />
            </template>
            Geçerli Bir Mail Adresi Giriniz
          </a-tag>
        </a-form-item>
        <a-form-item label='Şifre (*)'>
          <a-input-password v-model:value='password' name='password' type='password' />
          <a-tag color='error' v-if='password !== "" && (password.length < 6 || password.length > 64)' class='mt-2'>
            <template #icon>
              <i class='fe fe-alert-circle' />
            </template>
            Minimum 6, Maximum 64 Karakter olmalıdır
          </a-tag>
        </a-form-item>

        <div class='row'>
          <div class='col-12'>
            <a-tag color='error'>
              <template #icon>
                <i class='fe fe-alert-circle' />
              </template>
              Yıldızlı (*) Alanlar Zorunludur.
            </a-tag>
          </div>
        </div>
      </a-form>
    </a-modal>
    <a-modal v-model:visible='visibleEditModal' centered title='Yöneticiyi Düzenle'>
      <template #footer>
        <a-button key='back' @click='visibleEditModal = false'>Kapat</a-button>
      </template>

      <div class='card-body'>
        <a-form
          :loading='loading'
          label-align='left'
          layout='vertical'
        >

          <a-form-item label='Adı' name='nameSurname'>
            <a-input disabled v-model:value='currentAdminName' />
          </a-form-item>

          <a-form-item label='Mail Adresi' name='mail'>
            <a-input v-model:value='currentAdminEmail' />

            <a-tag color='error' v-if='currentAdminEmail !== "" & !validateEmail(currentAdminEmail)' class='mt-2'>
              <template #icon>
                <i class='fe fe-alert-circle' />
              </template>
              Geçerli Bir Mail Adresi Giriniz
            </a-tag>

            <a-button v-if='showMailSaveButton && validateEmail(currentAdminEmail)' @click='updateAdminMail' class='btn btn-success mt-2'>
              Güncelle
            </a-button>
          </a-form-item>

          <a-form-item label='Şifre' name='password'>
            <a-input-password v-model:value='currentAdminPassword' />

            <a-tag color='error' v-if='currentAdminPassword !== "" && !showPasswordSaveButton' class='mt-2'>
              <template #icon>
                <i class='fe fe-alert-circle' />
              </template>
              Minimum 6, Maximum 64 Karakter olmalıdır
            </a-tag>

            <a-button v-if='showPasswordSaveButton' @click='updateAdminPassword' class='btn btn-success mt-2'>
              Güncelle
            </a-button>
          </a-form-item>

        </a-form>
      </div>

    </a-modal>
  </div>
</template>

<script>
import { notification } from 'ant-design-vue'
import { validateEmail } from '../../utils/email'
import { AdministratorAdministration } from '../../services/api-service'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    slots: { customRender: 'id' },
  },
  {
    title: 'Adı Soyadı',
    dataIndex: 'name',
    slots: { customRender: 'nameSurname' },
  },
  {
    title: 'Mail Adresi',
    dataIndex: 'email',
    className: 'w-100',
    slots: { customRender: 'mail' },
  },
  {
    title: 'Yönet',
    dataIndex: 'action',
    className: 'text-center',
    slots: { customRender: 'operation' },
  },
]

export default {
  name: 'VbYeniSistemYNeticisiEkle',
  data() {
    const showEditModal = (id) => {
      this.visibleEditModal = true
      this.currentAdmin = this.administrators.find(x => x.id === id)
      if (this.currentAdmin) {
        this.currentAdminName = this.currentAdmin.name
        this.currentAdminEmail = this.currentAdmin.email
        this.currentAdminEmailFromServer = this.currentAdmin.email
      }
    }

    return {
      validateEmail,
      showEditModal,
      columns,
      name: '',
      lastName: '',
      email: '',
      password: '',
      administrators: [],
      loading: false,
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },

      visibleAddAdminModal: false,
      visibleEditModal: false,
      currentAdmin: {},
      currentAdminName: '',
      currentAdminEmail: '',
      currentAdminEmailFromServer: '',
      currentAdminPassword: '',
    }
  },
  computed: {
    showMailSaveButton() {
      return this.currentAdminEmail !== this.currentAdminEmailFromServer
    },
    showPasswordSaveButton() {
      return this.currentAdminPassword !== '' && (this.currentAdminPassword.length > 6 && this.currentAdminPassword.length < 64)
    },
    validateForm() {
      return this.validateEmail(this.email) && (this.password.length > 5 && this.password.length < 65) && this.name !== '' && this.lastName !== ''
    },
  },
  mounted() {
    this.listAdministrators()
  },
  methods: {
    async listAdministrators() {
      this.loading = true

      try {
        const result = await AdministratorAdministration.listAdministrators(this.pagination.currentPage)
        this.administrators = result.data.administratorList
        this.pagination = result.data.pagination
      } catch {
      }

      this.loading = false
    },
    async addNewAdmin() {
      this.loading = true

      try {
        const { data } = await AdministratorAdministration.createAdministrator({ name: this.name, lastName: this.lastName, email: this.email, password: this.password })

        if (data) {

          this.administrators = [...this.administrators, {
            id: data.id,
            name: this.name + ' ' + this.lastName,
            email: this.email,
            password: this.password,
          }]

          this.name = ''
          this.lastName = ''
          this.email = ''
          this.password = ''

          this.visibleAddAdminModal = false

          notification.success({
            message: 'Yeni Yönetici Başarıyla Oluşturuldu!',
          })
        }
      } catch {
      }

      this.loading = false
    },
    async confirmDelete(id) {
      this.loading = true

      try {
        await AdministratorAdministration.deleteAdministrator(id)
        this.administrators = this.administrators.filter((r) => r.id !== parseInt(id))

        notification.success({
          message: 'Yönetici Başarıyla Silindi!',
        })
      } catch {
      }

      this.loading = false
    },
    async updateAdminMail() {
      try {
        await AdministratorAdministration.changeAdministratorEMail({ id: this.currentAdmin.id, email: this.currentAdminEmail })
        this.currentAdminEmailFromServer = this.currentAdminEmail

        this.currentAdmin.email = this.currentAdminEmail
        const indexOldElement = this.administrators.findIndex(({ id }) => id === this.currentAdmin.id)
        this.administrators = Object.assign([...this.administrators], { [indexOldElement]: this.currentAdmin })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Mail Adresi Başarıyla Güncellendi!',
        })
      } catch {
      }
    },
    async updateAdminPassword() {
      try {
        await AdministratorAdministration.changeAdministratorPassword({ id: this.currentAdmin.id, password: this.currentAdminPassword })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Şifre Başarıyla Güncellendi!',
        })
        this.password = ''
      } catch {
      }
    },
  },
}
</script>
